<template>
  <Head>
    <title v-if="program && program.meta_title !== null">
      {{ program.meta_title }}
    </title>
    <title v-else>
      Best Online Coaching for Neet, IIT-JEE, CBSE,CA and Junior IAS Initiative
      - VB Live
    </title>
    <meta
      v-if="program && program.meta_description !== null"
      name="description"
      :content="program.meta_description"
    />
    <meta
      v-else
      name="description"
      content="VB Live is the Best Online Coaching Platform for NEET, IIT-JEE, CBSE, Junior IAS Initiative and CA Foundation. We provides all Courses from Class 6 to Class 12 with Experienced Faculty."
    />
    <meta
      v-if="program && program.meta_keywords !== null"
      name="keywords"
      :content="program.meta_keywords"
    />
    <meta
      v-else
      name="keywords"
      content="online training, courses online, online training courses, online tuition, online tuition classes for class 10, best online tuition for class 10, Best online tuition, best courses online, best online training, online tuition classes for class 12, best online tuition for class 12, best online coaching, ias online classes"
    />
  </Head>
  <div class="detail">
    <div class="banner-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <!-- <h1>All new learning experience</h1> -->
            <div v-if="loading" class="loading">Loading...</div>
            <h1 v-else-if="program">{{ program.program_title }}</h1>
            <p v-if="program" v-html="program.short_description"></p>
            <ul v-if="program" class="list-unstyled sub-list">
              <li
                v-for="program_subject in program.program_subjects"
                :key="program_subject.subject_id"
              >
                {{ program_subject.subject.subject_title }}
              </li>
            </ul>
            <p v-if="program">Board : {{ program.board.board_title }}</p>
            <p v-if="program">
              Standard : {{ program.standard.standard_title }}
            </p>
            <!-- <p v-if="program">
              Start Date : {{ getDate(program.start_date) }} | End Date :
              {{ getDate(program.end_date) }}
            </p>
            <p v-if="program">
              <span>Purchase Start Date :</span>
              {{ getDate(program.purchase_start_date) }}
            </p>
            <p v-if="program">
              <span>Purchase End Date :</span>
              {{ getDate(program.purchase_end_date) }}
            </p> -->
          </div>
          <div class="col-md-5">
            <div v-if="program" class="video-wrap">
              <img
                v-if="program.program_banner != null"
                :src="program.program_banner"
                class="card-img-top"
                alt=""
              />
              <img
                v-else
                src="../assets/Course-single-img.png"
                class="card-img-top"
                alt="..."
              />

              <!-- <a href="#">
                <img class="player-icon" src="../assets/play-icon.svg" alt="" />
              </a> -->
            </div>
          </div>
        </div>
        <div class="offdrop-box">
          <div v-if="loading" class="loading">Loading...</div>
          <div class="price" v-else-if="program">
            <h4>
              <del v-if="program.price.off_amount > 0"
                >₹ {{ program.price.price }}</del
              >
              ₹ {{ program.price.final_price }}
            </h4>
            <span class="price-info-text">(inclusive of GST)</span>
          </div>
          <router-link
            v-if="program && program.purchase_status === true"
            :to="{ name: 'CourseCheckout', params: { program_id: program.id } }"
          >
            <a class="btn cta-primary">buy now</a>
          </router-link>
          <small
            class="cta-info"
            v-if="program && program.purchase_status === false"
          >
            {{ program.purchase_status_comment }}
          </small>
        </div>
      </div>
    </div>

    <div class="highlight-wrapper">
      <div class="container">
        <div class="overview">
          <h2>Course overview</h2>
          <div v-if="loading" class="loading">Loading...</div>
          <p v-else-if="program" v-html="program.program_description"></p>
          <h2>class highlight</h2>
          <div v-if="loading" class="loading">Loading...</div>
          <p v-else-if="program" v-html="program.program_highlight"></p>
        </div>
      </div>
    </div>
    <FAQ />
    <div class="review-wrapper">
      <div class="experience-wrapper container">
        <Testimonial
          v-if="sortedTestimonials.length > 0"
          :sortedTestimonials="sortedTestimonials"
        />
      </div>
    </div>

    <CareerCounselling />
  </div>
</template>

<script>
import CourseService from "@/services/CourseService";
import CommonService from "@/services/CommonService";
import Testimonial from "@/components/Testimonial.vue";
import FAQ from "@/components/FAQ.vue";
import CareerCounselling from "@/components/CareerCounselling.vue";
import Timezone from "@/services/Timezone";
import { Head } from "@vueuse/head";

export default {
  name: "CourseDetails",
  components: {
    Head,
    Testimonial,
    FAQ,
    CareerCounselling,
  },
  data() {
    return {
      loading: true,
      // params: {
      //   // program_id: this.$route.params.program_id,
      //   slug: ,
      // },
      // Note:- declare program: null, if v-if="program" is going to use
      program: null,
      sortedTestimonials: [],
    };
  },
  created() {
    this.loadProgram();
    this.fetchTestimonials();
  },
  watch: {
    "$route.params.slug": function () {
      if (
        this.$route.params.slug != undefined &&
        this.$route.name == "CourseDetails"
      ) {
        this.loading = true;
        this.loadProgram();
        this.fetchTestimonials();
      }
    },
  },
  methods: {
    async loadProgram() {
      let params = {
        slug: this.$route.params.slug,
      };
      await CourseService.getProgramData(params)
        .then((response) => {
          this.loading = false;
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.program = response.data.program;
          }
          if (response.data.status === "ERROR") {
            this.$router.push({
              name: "NotFound",
              // preserve current path and remove the first char to avoid the target URL starting with `//`
              params: { pathMatch: this.$route.path.substring(1).split("/") },
              // preserve existing query and hash if any
              // query: this.$route.query,
              // hash: this.$route.hash,
            });
            // this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
          // console.log(this.program);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDate(date) {
      return Timezone.convertedTimeZone(date).format("ddd, Do MMM, YYYY");
    },
    fetchTestimonials() {
      CommonService.getTestimonials()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            let testimonials = response.data.testimonials;
            let count = testimonials.length;
            let j = 0;
            let k = 0;
            for (let i = 1; i <= count; i++) {
              j = k;
              k = k + 2;
              if (j >= count) {
                break;
              } else {
                this.sortedTestimonials.push(testimonials.slice(j, k));
              }
            }
            // console.log(this.sortedTestimonials);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/course-detail.scss";
</style>
